/**
 * 导出表格为excel并自定义表头
 * tableName为表头，格式为['id', 'name']
 * tableData为数据，格式为[[1, 'paidaxing'], [2, 'zhangyuge']]
 */
import * as XLSX from "xlsx";

export const exportToExcel = (tableData, tableName, sheetName, fileName) => {
  const ws = XLSX.utils.aoa_to_sheet([...[tableName], ...tableData]); // tableName为表头，tableData为数据
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, sheetName); // sheetName为自定义的sheet表名
  XLSX.writeFile(wb, fileName + ".xlsx"); // fileName为自定义的文件名
};
